import React, { useState, useContext, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { TPage, AutoBotPromptInput } from '../components';
import { AppContext } from '../context/AppContext';
import { TToggleItem } from '../components';

import {
  IonProgressBar,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonText
} from '@ionic/react';
import { appNotification } from '../core';
import { AutoBotFormData } from '../types/AutoBot';
import { autobotService } from '../services';
import '../styles/components/AutoBotWizard.scss';

interface StepsProps {
  title: string;
  explanation: string;
  field: 'name' | 'classification' | 'statusIds' | 'lastMessageAge' | 'importedReplied' | 'prompt';
  placeholder?: string;
}

const AutoBotWizard: React.FC = () => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const { state: appState } = useContext(AppContext);
  const { selectedClient, user, statuses } = appState;
  const [name, setName] = useState('');
  const [classification, setClassification] = useState('');
  const [statusIds, setStatusIds] = useState<number[]>([]);
  const [lastMessageAge, setLastMessageAge] = useState(24);
  const [prompt, setPrompt] = useState('');
  const [importedReplied, setImportedReplied] = useState('2');

  const leadOptions = useMemo(() => {
    const options = ['Sales', 'Service', 'Employment', 'Collections'];
    if (user.is_staff) options.push('Service Canceled');
    return options;
  }, [user])

  const steps: StepsProps[] = [
    {
      title: 'Auto Bot Name',
      explanation: 'Give your bot a name. This name is only for internal use and won\'t be used by the bot in communication with the customer. So name it whatever you want.',
      field: 'name',
      placeholder: 'Enter a name for your Auto Bot'
    },
    {
      title: 'Lead Classification',
      explanation: 'What classification of leads do you want to target with this bot?',
      field: 'classification',
      placeholder: 'Sales'
    },
    {
      title: 'Statuses',
      explanation: 'What lead statuses do you want to target with this bot?',
      field: 'statusIds',
      placeholder: 'Active-Contacted'
    },
    {
      title: 'Time to Wait',
      explanation: 'How long after the last text message do you want this bot to follow up with these leads?',
      field: 'lastMessageAge'
    },
    {
      title: 'Imported Leads',
      explanation: 'Target your imported leads only?  Toggling this setting to "on" will cause this bot to only contact leads that have been imported into TECOBI but have never replied with an inbound message.',
      field: 'importedReplied',
      placeholder: 'Imported Leads Only'
    },
    {
      title: 'Prompt',
      explanation: 'Do you want your bot to be friendly, funny, assertive, persistent or professional?',
      field: 'prompt',
      placeholder: 'This is a conversation between {{ client }} and {{ dealership }}. Craft a message to {{ client }} in the form of a question. Use a polite, cheerful tone. Follow all rules in the Mandatory Rules section. Make sure to not sound repetative.'
    },
  ];

  const nextStep = useCallback(() => {
    if (step < steps.length) {
      setStep(step + 1)
    }
  }, [step, steps.length]);

  const previousStep = useCallback(() => {
    if (step > 1) {
      setStep(step - 1);
    }
  }, [step]);

  const valid = () => {
    return (
      name !== ''
      && classification !== ''
      && lastMessageAge > 0
      && statusIds.length > 0
      && prompt !== ''
    )
  }

  const handleFormSubmit = async () => {
    const data: AutoBotFormData = {
      name: name,
      last_message_age_hours: lastMessageAge,
      lead_classification: classification,
      pre_template: prompt,
      imported_replied: importedReplied,
      statuses: statusIds
    }
    const response = await autobotService.create(selectedClient.id, data);
    if (response) {
      appNotification.toast('Auto Bot created');
      history.push(`/autobots/bots/${response.id}/`)
    }
  };

  return (
    <TPage headerTool={<></>}>
      <IonProgressBar value={(step - 1) / (steps.length - 1)} />
      <IonCard className="wizard-card">
        <IonCardHeader>
          <IonCardTitle>
            {steps[step - 1].title}
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent className="wizard-card-content">
          <div>
            <IonText><p>{steps[step - 1].explanation}</p></IonText>
            {step === 1 && (
              <IonItem className="ion-margin-top ion-margin-bottom">
                <IonInput
                  fill="outline"
                  placeholder={steps[step - 1].placeholder}
                  value={name}
                  onIonInput={(e: CustomEvent) => {
                    setName(e.detail.value)
                  }}
                />
              </IonItem>
            )}
            {step === 2 && (
              <IonItem className="ion-margin-top ion-margin-bottom">
                <IonSelect
                  fill="outline"
                  placeholder={steps[step - 1].placeholder}
                  value={classification}
                  onIonChange={(e: CustomEvent) => {
                    setClassification(e.detail.value);
                  }}
                >
                  {leadOptions.map((option) => (
                    <IonSelectOption value={option} key={option}>{option}</IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            )}
            {step === 3 && (
              <IonItem className="ion-margin-top ion-margin-bottom">
                <IonSelect
                  fill="outline"
                  placeholder={steps[step - 1].placeholder}
                  value={statusIds}
                  multiple
                  onIonChange={(e) => {
                    setStatusIds(e.detail.value as number[]);
                  }}
                >
                  {statuses.map(stat => (
                    <IonSelectOption value={stat.id} key={stat.id}>{stat.option_name}</IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            )}
            {step === 4 && (
              <IonItem className="ion-margin-top ion-margin-bottom">
                <IonInput
                  fill="outline"
                  type="number"
                  value={lastMessageAge}
                  onIonInput={(e: CustomEvent) => {
                    setLastMessageAge(Number(e.detail.value))
                  }}
                />
              </IonItem>
            )}
            {step === 5 && (
              <TToggleItem
                className="ion-margin-top ion-margin-bottom"
                label={steps[step - 1].placeholder}
                checked={importedReplied==='0'}
                onIonChange={(e: any) => (e.detail.checked ? setImportedReplied('0') : setImportedReplied('2'))}
                wrapLabel
              />
            )}
            {step === 6 && (
              <AutoBotPromptInput
                placeholder={steps[step - 1].placeholder}
                outline
                showLabel={false}
                setState={setPrompt}
                state={prompt}
              />
            )}
          </div>
          <div className="wizard-button-container">
            {step > 1 ? <IonButton onClick={previousStep}>Previous</IonButton> : <div />}
            {step < steps.length ? (
              <IonButton onClick={nextStep}>Next</IonButton>
            ) : (
              <IonButton onClick={handleFormSubmit} color="secondary" disabled={!valid()}>Submit</IonButton>
            )}
          </div>
        </IonCardContent>
      </IonCard>
    </TPage>
  )
}

export default AutoBotWizard;

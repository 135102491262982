import React, { useContext, useState } from 'react';
import { ForcedTrainingSessionModal } from '../components/modals';
import { BlastDetailModal } from '../components/modals';
import { blastsService } from '../services';
import { loadingIndicator } from '../core';

import {
  IonItem,
  IonIcon,
  IonLabel,
  IonSpinner,
  IonImg,
  IonButton,
} from '@ionic/react';

import bars from '../icons/bars.svg';
import {
  checkmarkCircle,
  mail,
  helpBuoy,
  refresh,
  card,
  arrowRedo,
} from 'ionicons/icons';
import TImage from './TImage';
import TAudio from './TAudio';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import '../styles/components/ChatBubble.scss';
import TMedia from './TMedia';
import PhoneCallChips from './PhoneCallChips';
import css from 'classnames';
import AudioNotAvailable from './AudioNotAvailable';
import { LeadContext } from '../context/LeadContext';
import { AppContext } from '../context/AppContext';
import { util } from '../core';
import { useRouter } from '../hooks';
import TimeAgo from 'react-timeago';

export interface ChatBubbleProps {
  id: number;
  senderId?: number|null;
  adPreview?: any;
  typing?: boolean;
  message?: string;
  sender?: string;
  dateSent?: string;
  mediaSet?: any[];
  read?: boolean;
  ninja?: boolean;
  senderIs1099?: boolean;
  internal?: boolean;
  outgoing?: boolean;
  isEmail?: boolean;
  gif?: string;
  video?: string;
  videoThumb?: string;
  twilioStatus?: string;
  outboundEmailStatus?: string;
  yellow?: boolean;
  orange?: boolean;
  beige?: boolean;
  red?: boolean;
  call?: any;
  resetPause?: any;
  lines?: HTMLIonItemElement['lines'];
  translation?: string;
  videoMeetingStatus?: string;
  language?: any;
  hideAudio?: boolean;
  timeSince?: string;
  linkToConversation?: string;
  smsBlast?: any;
}

function formatMessage(message: string) {
  const urlRegex = /(https?:\/\/[^\s]+?)([.,]?)(\s|$)/g;
  const tcbldRegex = /([?&])tcbld=[^&]*$/; // Matches '?tcbld=<value>' or '&tcbld=<value>' at the end of a URL
  message = message.replace(urlRegex, (fullMatch, url, trailingChar, followingSpace) => {
    // Remove tcbld parameter if it exists at the end of the URL
    let cleanedUrl = url.replace(tcbldRegex, '');
    // Ensure no trailing '?' or '&' after removing the param
    cleanedUrl = cleanedUrl.replace(/[?&]$/, '');
    // Reconstruct the string with the URL linkified and the trailing character outside the link
    return `[${cleanedUrl}](${cleanedUrl})${trailingChar}${followingSpace}`;
  })
  .replace(/<o:/g, '<')
  .replace(/<meta[^>]+>/g, '');
  return message;
}

const ChatBubble: React.FC<ChatBubbleProps> = ({
  id,
  senderId,
  adPreview,
  typing,
  read,
  sender,
  dateSent,
  message,
  outgoing,
  internal,
  ninja,
  senderIs1099,
  gif,
  mediaSet,
  videoThumb,
  twilioStatus,
  outboundEmailStatus,
  yellow,
  call,
  resetPause,
  videoMeetingStatus,
  language,
  translation,
  timeSince,
  linkToConversation,
  smsBlast = null,
  orange = false,
  beige = false,
  red = false,
  hideAudio = false,
  isEmail = false,
  lines = 'none',
}) => {
  // Inside the ChatBubble function body:
  const [showTrainingModal, setShowTrainingModal] = useState(false);
  const [blastDetail, setBlastDetail] = useState<any|undefined>(undefined);

  // Toggle the modal's visibility
  const handleSenderClick = () => {
    if (senderId) setShowTrainingModal(true);
  }

  const handleBlasterClick = async () => {
    if (smsBlast) {
      loadingIndicator.create();
      const b = await blastsService.get(smsBlast.client, smsBlast.id, 'leads');
      loadingIndicator.dismiss();
      setBlastDetail(b);
    }
  }

  const router = useRouter();
  const { resetPauseInLead, dispatch } = useContext(LeadContext);
  const { state } = useContext(AppContext);
  const { user } = state;
  if (videoMeetingStatus && message && !videoThumb) {
    message = videoMeetingStatus === 'completed' ? ' ' : message;
  }
  if (call?.summary) {
    message = `${message}:\n\n ${call.summary.replace(
      /\[(\d+)\]/g,
      'Score: $1'
    )}`;
  }
  if (call?.user) {
    sender = `${call?.user?.first_name} ${call?.user?.last_name}`;
  }
  if (smsBlast?.user) {
    sender = 'Human blast'
  }
  if (smsBlast?.user && smsBlast?.ai_generated_message) {
    sender = 'Human/AI blast'
  }
  if (smsBlast?.is_created_by_ai) {
    sender = 'TECOBI Auto Bot'
  }
  let ninjaLabel = ninja ? '(Ninja)' : '';
  if (senderIs1099) {
    ninjaLabel = '(1099)';
  }
  return (
    <IonItem
      title={`Message ID: ${id?.toString()}`}
      className={css('chat-bubble', {
        call,
        incoming: !outgoing,
        outgoing,
        yellow,
        orange,
        beige,
        red,
        'ad-preview': adPreview,
      })}
      lines={lines}
    >
      {(user?.is_admin || user?.is_client_admin) && !!senderId && showTrainingModal && (
        <ForcedTrainingSessionModal
          isOpen={true}
          userName={sender}
          user={senderId}
          onClose={() => setShowTrainingModal(false)}
        />
      )}
      {smsBlast && blastDetail && (
        <BlastDetailModal
          item={blastDetail}
          blastType={'leads'}
          onDidDismiss={() => setBlastDetail(undefined)}
        />
      )}
      <IonLabel className='chat-label ion-text-wrap'>
        <div className={css('bubble', { typing })}>
          {mediaSet &&
            mediaSet.map((it) => (
              <TMedia
                key={it.id}
                src={it.media}
                name={it?.media?.split('/')?.pop()?.split('?')?.[0]}
                alt={it.media}
                unsafe={it.unsafe}
                poster={videoThumb}
                onClick={(e: any) =>
                  dispatch({
                    type: 'set',
                    value: {
                      mediaPreview: {
                        height: e.target.naturalHeight,
                        width: e.target.naturalWidth,
                        src: it.media,
                        contentType: it.content_type,
                      },
                    },
                  })
                }
                contentType={it.content_type}
              />
            ))}
          {gif && gif.includes('vcard') ? (
            <div className="vcard-bubble">
              <IonIcon icon={card} className="vcard-icon" />
              <a href={gif} target="_blank" rel="noopener noreferrer">
                contact.vcf
              </a>
            </div>
          ) : (
            gif && (
              <TImage
                onClick={(e: any) =>
                  dispatch({
                    type: 'set',
                    value: {
                      mediaPreview: {
                        height: e.target.naturalHeight,
                        width: e.target.naturalWidth,
                        src: gif,
                        contentType: 'image/gif',
                      },
                    },
                  })
                }
                src={gif}
              />
            )
          )}
          {adPreview && <div dangerouslySetInnerHTML={{ __html: adPreview }} />}
          {message && (
            <div
              onMouseUp={(e: any) => {
                if (
                  message &&
                  ['A', 'IMG', 'VIDEO'].indexOf(e.target.tagName) === -1
                ) {
                  util.copyToClipboard(message);
                }
              }}
            >
              {videoMeetingStatus && !videoThumb && (
                <IonImg
                  style={{ maxWidth: 270 }}
                  src={
                    videoMeetingStatus === 'completed'
                      ? `/assets/video-meeting-recording-processing.png`
                      : `/assets/video-meeting-in-progress.png`
                  }
                  title={`Video Meeting ${videoMeetingStatus}`}
                  alt={`Video Meeting ${videoMeetingStatus}`}
                />
              )}
              <ReactMarkdown
                linkTarget='_blank'
                className='message-text'
                skipHtml={true}
                rehypePlugins={[rehypeRaw]}
                children={formatMessage(message)}
              />
            </div>
          )}
          {call && call.call_status === 'in-progress' && (
            <div style={{ textAlign: 'center', fontSize: 60 }}>
              <IonIcon src={bars} />
            </div>
          )}
          {translation && (
            <div className='translation'>
              <div className='translation-language'>
                Auto translated from {language?.name} to English
              </div>
              <div>{translation}</div>
            </div>
          )}
          {call && (
            <div className='message-text' style={{ fontSize: 10 }}>
              {call.from_number}, {call.from_city ? call.from_city + ', ' : ''}
              {call.from_state}
            </div>
          )}
          {call &&
            !hideAudio &&
            (call.audio_file || call.recording_url ? (
              <TAudio
                src={call.audio_file ?? call.recording_url}
                shareSrc={call.twilio_url}
                noSpeed
                noTime
                showLength
                background
                allowShare
              />
            ) : (
              <AudioNotAvailable background />
            ))}
          {resetPause && (
            <IonButton
              className='reset-pause'
              fill='clear'
              onClick={() => resetPauseInLead(resetPause, id)}
            >
              <IonIcon slot='start' icon={refresh} />
              Reset Pause
            </IonButton>
          )}
          {isEmail && (
            <div className='chat-internal-only'>
              <IonIcon icon={mail} />
              E-mail Message
            </div>
          )}
          {internal && (
            <div className='chat-internal-only'>
              <IonIcon icon={helpBuoy} />
              Internal Message
            </div>
          )}
        </div>
        {!!call?.category && <PhoneCallChips call={call} />}
        {sender && (
          <div className='chat-details'>
            {typing && <IonSpinner name='dots' />}
            {read && <IonIcon icon={checkmarkCircle} />}

            {
              !senderId && smsBlast?.id ? (
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={handleBlasterClick}
                >
                  {sender}
                </span>
              ) : !senderId ? (
                <>{sender}&nbsp;</>
              ) : (
                <span
                  style={
                    {cursor: (user?.is_admin && !!senderId) ? 'pointer' : undefined}
                  }
                  onClick={((user?.is_admin || user?.is_client_admin) && !!senderId) ? handleSenderClick : undefined}
                >
                  {sender}&nbsp;
                </span>
              )
            }

            {ninjaLabel}
            {twilioStatus && <strong>&nbsp;{twilioStatus}&nbsp;</strong>}
            {outboundEmailStatus && (
              <strong>{outboundEmailStatus}&nbsp;</strong>
            )}
            {dateSent ? ' at ' : ''} {dateSent}
            &nbsp;
            {!!linkToConversation && (
              <IonIcon
                onClick={() => router.push(linkToConversation, {})}
                className='pointer'
                icon={arrowRedo}
              />
            )}
            {timeSince && (
              <>
                &nbsp;
                <TimeAgo date={timeSince} live={true} />
              </>
            )}
          </div>
        )}
      </IonLabel>
    </IonItem>
  );
};

export default ChatBubble;

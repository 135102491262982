import { http } from "../core";

class LeadNextAutoBotMessageService {
  async get(clientId: number | string, leadId: number | string) {
    try {
      const { data } = await http.authorizedRequest({
        method: "GET",
        url: `/clients/${clientId}/leads/${leadId}/next-autobot-message/`,
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }
}

const leadNextAutoBotMessageService = new LeadNextAutoBotMessageService();
export default leadNextAutoBotMessageService;

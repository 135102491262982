import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { IonItem, IonIcon, IonLabel, IonReorder, IonText } from '@ionic/react';
import { AppContext } from '../context/AppContext';
import robot from '../icons/robot.svg';

export interface AutoBotItemProps {
  id: number;
  pre_template: string;
  last_message_age_hours: number;
  lead_classification: string;
  botStatuses: number[];
  name: string;
  imported_replied?: string;
  order: number;
}

const AutoBotRow: React.FC<AutoBotItemProps> = ({
  id,
  name,
  botStatuses,
  lead_classification,
  imported_replied,
  last_message_age_hours
}) => {
  const { state: appState } = useContext(AppContext);
  const { statuses } = appState;
  const history = useHistory();

  const statusNames = () => {
    if (botStatuses.length) {
      return botStatuses.map(status => {
        return statuses.find(s => s.id === status)?.option_name
      }).join(', ')
    }
  }

  return (
    <>
      <IonItem onClick={() => history.push(`/autobots/bots/${id}/`)} className="pointer">
        <IonIcon slot="start" src={robot} size="large" style={{ color: 'var(--ion-color-step-600)' }}/>
        <IonLabel className="detail">
          <IonText><h2>{name}</h2></IonText>
          <IonText><p>{`${lead_classification}: ${statusNames()}${imported_replied === '0' ? '. Imported leads only.': ''}`}</p></IonText>
          <IonText color='success'><p>{`Runs every ${last_message_age_hours} hours`}</p></IonText>
        </IonLabel>
        <IonReorder slot="end" onClick={e => e.stopPropagation()} />
      </IonItem>
    </>
  )
}

export default AutoBotRow;

import React, { useContext, useState } from "react";
import { LeadContext } from "../context/LeadContext";
import TExpandableItemGroup from "./TExpandableItemGroup";
import { IonItem, IonText, IonIcon, IonLabel } from "@ionic/react";

import { checkmarkCircleOutline, arrowUpCircleOutline } from "ionicons/icons";
import moment from "moment";
import TButtonItem from "./TButtonItem";
import { util, loadingIndicator } from "../core";
import { ChatMessageBoxContext } from "../context/ChatMessageBoxContext";
import { SoftPullModal } from "./modals";

const LeadSoftPulls: React.FC = () => {
  const { appendMessage } = useContext(ChatMessageBoxContext);
  const { state } = useContext(LeadContext);
  const { lead } = state;
  const softPulls = lead?.quick_qualifications || [];
  const [softPull, setSoftPull] = useState<any>();
  const isSoftPull = lead?.client_soft_pulls === true;

  const append = (c: string) => {
    appendMessage(c);
    util.hideLeadSidebar();
  };

  const badgeValue = softPulls?.length ?? 0;

  return (
    <TExpandableItemGroup title='Soft Pulls' badge={badgeValue}>
      {softPulls?.map((pull: any) => (
        <IonItem
          key={pull.guid}
          className='pointer'
          onClick={() => {
            setSoftPull(pull);
          }}
        >
          <IonIcon icon={checkmarkCircleOutline} slot='start' />
          <IonLabel className='ion-multiline'>
            <IonText>
              <span className='detail'>Soft Pull</span>
              <span>
                {pull.first_name} {pull.last_name}
              </span>
              <span className='detail'>
                {pull.bureau_name} • {pull.score_range}{" "}
                {pull.result_description ? " • " : ""} {pull.result_description}
              </span>
              <span className='detail'>
                {moment(pull.created_at).fromNow()}
              </span>
            </IonText>
          </IonLabel>
        </IonItem>
      ))}
      {isSoftPull && (
        <TButtonItem
          color='secondary'
          onClick={async () => {
            try {
              await loadingIndicator.create();
              //const softPullUrl = `https://ca.tecobi.com/quick-qualify/${lead?.client}/${lead?.id}/`;
              let softPullUrl = `https://profile.tecobi.com/${lead?.client_slug}`;
              let userSlug = !!lead?.assigned_to_info ? lead.assigned_to_info.calendars_slug : lead.client_robot_name;
              if (userSlug) {
                const encodedUserSlug = encodeURIComponent(userSlug.trim());
                softPullUrl += `/${encodedUserSlug}`;
              }
              softPullUrl += `/${lead.urlhash}?creditFormOpen=true&tcbld=${lead.id}`;
              const message = `Here is the link to quickly get pre-qualified: ${softPullUrl}`;
              append(message);
            } finally {
              loadingIndicator.dismiss();
            }
          }}
        >
          <IonIcon icon={arrowUpCircleOutline} slot='start' />
          Soft Pull Link
        </TButtonItem>
      )}
      {softPull && (
        <SoftPullModal
          isOpen={true}
          onDidDismiss={() => setSoftPull(null)}
          data={softPull}
        />
      )}
    </TExpandableItemGroup>
  );
};

export default LeadSoftPulls;

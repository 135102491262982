import React, { useContext, useEffect, useState } from "react";
import {
  IonText,
  IonRow,
  IonItem,
  IonCol,
  IonSpinner,
  IonList,
  IonLabel,
} from "@ionic/react";
import TimeAgo from "react-timeago";
import { leadNextAutoBotMessageService } from "../services";
import { LeadContext } from "../context/LeadContext";
import TExpandableItemGroup from "./TExpandableItemGroup";
import ChatBubble from "./ChatBubble"; // Import ChatBubble
import moment from "moment";

const LeadNextAutoBotMessage: React.FC = () => {
  const { state } = useContext(LeadContext);
  const { lead } = state;
  const [autoBotMessage, setAutoBotMessage] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const clientId = lead?.client;
  const leadId = lead?.id;

  useEffect(() => {
    if (!leadId || !clientId) {
      return;
    }

    const fetchNextAutoBotMessage = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await leadNextAutoBotMessageService.get(clientId, leadId);
        setAutoBotMessage(data);
      } catch (err: any) {
        setError(err.message || "An error occurred.");
      } finally {
        setLoading(false);
      }
    };

    fetchNextAutoBotMessage();
  }, [clientId, leadId]);

  if (!lead) {
    return null;
  }

  return (
    <TExpandableItemGroup
      title='Next AutoBot Message'
      badge={autoBotMessage?.message ? 1 : undefined}
    >
      {loading ? (
        <div className='loading'>
          <IonSpinner name='crescent' />
          <IonText>Loading next autobot message...</IonText>
        </div>
      ) : error ? (
        <IonText color='danger'>Error: {error}</IonText>
      ) : !autoBotMessage?.message ? (
        <IonRow>
          <IonItem>
            <IonText>No AutoBot message available for this lead.</IonText>
          </IonItem>
        </IonRow>
      ) : (
        <IonList className='no-padding'>
          {/* Message Preview */}
          <IonItem color='danger' lines='none'>
            Example Message
          </IonItem>
          <ChatBubble
            id={autoBotMessage.id}
            sender='AutoBot'
            message={autoBotMessage.message}
            lines='full'
            dateSent={moment(autoBotMessage.expected_message_send_time).format(
              "MMM D, YYYY h:mma"
            )}
            mediaSet={[]} // Add any media handling logic here if needed
            outgoing
          />

          {/* AutoBot Details */}
          <IonRow>
            <IonCol size='12'>
              <IonItem lines='full'>
                <IonLabel>
                  <strong>AutoBot Name:</strong>{" "}
                  {autoBotMessage.autobot?.name || "No Bot Name"}
                </IonLabel>
              </IonItem>
              <IonItem lines='none'>
                <IonLabel>
                  <strong>Expected Message Time:</strong>{" "}
                  <TimeAgo date={autoBotMessage.expected_message_send_time} />
                </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonList>
      )}
    </TExpandableItemGroup>
  );
};

export default LeadNextAutoBotMessage;

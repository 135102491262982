import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { sentry } from './core';

import App from './App';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { BrowserRouter } from 'react-router-dom';
// window.process = {
//     ...window.process,
//   };
// ReactDOM.render(<App />, document.getElementById('root'));

sentry.initialize();

const rootElement = document.getElementById('root');

if (rootElement) {
  createRoot(rootElement).render(<BrowserRouter><App/></BrowserRouter>);
} else {
  console.error('Could not find root element in the HTML document');
}
defineCustomElements(window);

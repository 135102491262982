import React from "react";
import { IonItem, IonIcon, IonButton, IonLabel, IonText } from "@ionic/react";
import moment from "moment";
import { ellipsisVertical } from "ionicons/icons";
import "../styles/components/BlastRow.scss";
import TAvatar from "./TAvatar";
import { actionSheet, appNotification } from "../core";
import { blastsService } from "../services";
import { useRouter } from "../hooks";

export interface BlastRowProps {
  item: any;
  hideActionButton?: boolean;
  blastType: string;
  clientName?: string;
  onClick?: (e: any) => any;
  onBlastRemoved: (id: any) => any;
}

const BlastRow: React.FC<BlastRowProps> = ({
  item,
  hideActionButton,
  blastType,
  clientName,
  onClick,
  onBlastRemoved,
}) => {
  const {
    id,
    client,
    sender,
    send_at,
    completed_at,
    deleted_at,
    response_rate,
    sent_count,
    sender_profile_pic,
    status_names,
    message,
  } = item;
  const router = useRouter();
  const showMoreActionSheet = (e: any) => {
    e.stopPropagation();

    const buttons = [
      {
        text: "Dismiss",
        role: "cancel",
      },
    ] as any[];

    if (blastType === "leads") {
      buttons.unshift({
        text: "Duplicate",
        handler: () => router.push(`/blasts/leads/add/duplicate/${id}/`, {}),
      });
    }

    if (completed_at || !deleted_at) {
      buttons.unshift({
        text: completed_at ? "Archive" : "Cancel Send",
        handler: () => {
          (async () => {
            await blastsService.deleteBlast(client, id, blastType === "leads");
            onBlastRemoved(id);
            appNotification.toast(
              `Blast has been ${completed_at ? "archived" : "cancelled"}.`,
              completed_at ? "Archived" : "Cancelled"
            );
          })();
          return true;
        },
      });
    }

    actionSheet.show(buttons);
  };

  const { icon, color } = blastsService.getStatusIcon(item);

  return (
    <IonItem className='blast-row ion-activatable pointer' onClick={onClick}>
      <TAvatar
        src={sender_profile_pic}
        alt={sender}
        badge={<IonIcon icon={icon} />}
        badgeTextColor={`var(--ion-color-${color}-contrast)`}
        badgeColor={`var(--ion-color-${color})`}
        slot='start'
      />
      <IonLabel className='ion-multiline'>
        <IonText>
          <h2>
            {sender}
            {clientName && <em>&nbsp;({clientName})</em>}
          </h2>
          <p className='detail'>{message}</p>
          <p className='detail'>
            {moment(send_at).format("MM/DD/YY hh:mma")} • {sent_count} Sent •{" "}
            {response_rate}% Response •{" "}
            {status_names?.length > 3
              ? `${status_names.slice(0, 3).join(", ")}...`
              : status_names?.join(", ")}
          </p>
        </IonText>
      </IonLabel>
      {!hideActionButton && (!!completed_at || !deleted_at) && (
        <IonButton
          slot='end'
          fill='clear'
          onClick={showMoreActionSheet}
          size='default'
        >
          <IonIcon slot='icon-only' icon={ellipsisVertical} />
        </IonButton>
      )}
    </IonItem>
  );
};

export default BlastRow;

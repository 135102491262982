import { http, util } from '../core';
import qs from 'qs';
import PagedService from './paged-service';

export enum INVENTORY_DEFAULT_MILEAGE_RANGE {
  Min = 0,
  Max = 300000
}

class InventoryService extends PagedService {
  private getFiltersQuery({
    search,
    bodyStyle,
    ordering,
    yearName,
    makeName,
    exteriorColorName,
    driveTypeName,
    transmissionTypeName,
    engineName,
    fuelTypeName,
    modelName,
    trimName,
    priceMin,
    priceMax,
    mileageMin,
    mileageMax,
    condition,
    clientId,
    pageSize
  }: any) {
    const params = { in_stock: true } as any;

    if (search) {
      params.search = search;
    }
    if (ordering) {
      params.ordering = ordering;
    }
    if (bodyStyle) {
      params.body_name__in = bodyStyle.join(',');
    }
    if (yearName) {
      params.year_name__in = yearName.join(',');
    }
    if (makeName) {
      params.make_name__in = makeName.join(',');
    }
    if (exteriorColorName) {
      params.exterior_color_name__in = exteriorColorName.join(',');
    }
    if (driveTypeName) {
      params.drive_type_name__in = driveTypeName.join(',');
    }
    if (transmissionTypeName) {
      params.transmission_type_name__in = transmissionTypeName.join(',');
    }
    if (engineName) {
      params.engine_name__in = engineName.join(',');
    }
    if (fuelTypeName) {
      params.fuel_type_name__in = fuelTypeName.join(',');
    }
    if (modelName) {
      params.model_name__in = modelName.join(',');
    }
    if (trimName) {
      params.trim_name__in = trimName.join(',');
    }
    if (priceMax) {
      params.selling_price__lte = priceMax;
    }
    if (priceMin) {
      params.selling_price__gte = priceMin;
    }
    if (mileageMax < INVENTORY_DEFAULT_MILEAGE_RANGE.Max) {
      params.mileage__lte = mileageMax;
    }
    if (mileageMin) {
      params.mileage__gte = mileageMin;
    }
    if (condition) {
      params.condition_name__iexact = condition;
    }
    if (clientId) {
      params.client__id = clientId;
    }
    if (pageSize) {
      params.page_size = pageSize;
    }
    return qs.stringify(params);
  }

  async fetchFilterOptions({
    clientId,
    search,
    bodyStyle,
    ordering,
    yearName,
    makeName,
    exteriorColorName,
    driveTypeName,
    transmissionTypeName,
    engineName,
    fuelTypeName,
    modelName,
    trimName,
    priceMin,
    priceMax,
    mileageMin,
    mileageMax,
    condition
  }: any) {
    try {
      const query = this.getFiltersQuery({
        search,
        bodyStyle,
        yearName,
        makeName,
        exteriorColorName,
        driveTypeName,
        transmissionTypeName,
        engineName,
        fuelTypeName,
        modelName,
        trimName,
        priceMin,
        priceMax,
        mileageMin,
        mileageMax,
        condition
      });

      const res = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/${clientId}/inventory-filter-options/?${query}`,
        exclusive: true,
        type: 'inventory-filter-options'
      });

      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async list({
    clientId,
    search,
    bodyStyle,
    ordering,
    yearName,
    makeName,
    exteriorColorName,
    driveTypeName,
    transmissionTypeName,
    engineName,
    fuelTypeName,
    modelName,
    trimName,
    priceMin,
    priceMax,
    mileageMin,
    mileageMax,
    condition
  }: any) {
    try {
      const query = this.getFiltersQuery({
        search,
        bodyStyle,
        ordering,
        yearName,
        makeName,
        exteriorColorName,
        driveTypeName,
        transmissionTypeName,
        engineName,
        fuelTypeName,
        modelName,
        trimName,
        priceMin,
        priceMax,
        mileageMin,
        mileageMax,
        condition
      });

      const res = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/${clientId}/inventory/?${query}`,
        exclusive: true,
        type: 'inventory-search'
      });

      return res.data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async get(clientId: any, id: any) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/${clientId}/inventory/${id}/`
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async showcase(id: any, leadId?: any) {
    try {
      const query = qs.stringify({ lead: leadId });
      const { data } = await http.request({
        method: 'GET',
        url: `/inventory/${id}/?${query}`
      });
      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async showcaseResponse(clientId: any, leadId: any, id: any, like: boolean) {
    try {
      const { data } = await http.request({
        method: 'POST',
        url: '/inventory-like/',
        data: {
          client: clientId,
          lead: leadId,
          inventory: id,
          like
        }
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async loadBodyStyles() {
    try {
      const { data } = await http.request({
        method: 'GET',
        url: '/body-style-choices/'
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async getRangeLimits(clientId: any) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/${clientId}/inventory-prices/`
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  getInventoryUrl(clientSlug: string, leadHash: string, id: any, userSlug?: string, leadId?: number) { 
    let inventoryUrl = `https://profile.tecobi.com/${clientSlug}`;
    if (userSlug) {
      const encodedUserSlug = encodeURIComponent(userSlug.trim());
      inventoryUrl += `/${encodedUserSlug}`;
    }
    inventoryUrl += `/${leadHash}/car/${id}?tcbld=${leadId}`;
    return inventoryUrl;
  }

  async searchPublicInventory(clientId: any, filters: any = {}) {
    const query = this.getFiltersQuery({ ...filters, clientId });
    try {
      const { data } = await http.request({
        method: 'GET',
        url: `/inventory/?${query}`
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  getSellingPrice(price: any) {
    if (price === 0) {
      return 'Call for Price';
    }

    return util.formatDollar(price);
  }
}

export enum INVENTORY_DEFAULT_PRICE_RANGE {
  Min = 0,
  Max = 900000
}

const inventoryService = new InventoryService();
export default inventoryService;
